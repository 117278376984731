import { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';

import { AuthContextType, AuthControllerProps } from './AuthContext.types';

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthController = ({ children }: AuthControllerProps) => {
  const [token, setToken] = useState<string | null>(null);

  const authHandler = useCallback(async () => {
    const {
      data: { token },
    } = await axios.post(`/api/auth`);

    setToken(token);
  }, []);

  useEffect(() => {
    authHandler();
  }, [authHandler]);

  return <AuthContext.Provider value={{ token }}>{children}</AuthContext.Provider>;
};
