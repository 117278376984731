import Image from 'next/image';
import clsx from 'clsx';

import { ServicesItem } from 'components/services-item/services-item';
import { useRemoveScroll } from 'hooks/useRemoveScroll/useRemoveScroll';
import { useLocale } from 'hooks/useLocale/useLocale';
import { MENU_LINKS } from 'assets/data/menu-links';
import { SERVICES } from 'assets/data/services';
import TojjarIcon from 'assets/images/tojjar-icon.svg';
import CloseIcon from 'assets/images/close-icon.svg';
import MenuIcon from 'assets/images/menu-icon.svg';
import { AppMessages } from 'i18n/messages';
import { usePageType } from 'hooks/usePageType/usePageType';

import styles from './mobile-menu.module.scss';

type MobileMenuProps = {
  onClose: () => void;
};

export const MobileMenu = ({ onClose }: MobileMenuProps) => {
  const { formatMessage } = useLocale();
  useRemoveScroll();

  const { isBooths, isApps } = usePageType();

  const isActiveClass = (linkName: string) => {
    return (linkName === 'Booths' && isBooths) || (linkName === 'Apps' && isApps);
  };

  return (
    <div className={styles.mobileMenu}>
      <div className={styles.navbar}>
        <div className={styles.logo}>
          <Image priority src={TojjarIcon} alt="tojjar" />
        </div>
        <button className={styles.close} onClick={onClose}>
          <Image priority src={CloseIcon} height={24} width={24} alt="close" />
        </button>
      </div>
      <div className={styles.scroll}>
        <div className={styles.content}>
          {MENU_LINKS.map((link) => (
            <a
              key={link.name}
              href={link.url}
              className={clsx(styles.link, isActiveClass(link.name) && styles.activeLink)}
            >
              {formatMessage({
                id: AppMessages[link.translationKey],
              })}
            </a>
          ))}
        </div>
        <div className={styles.services}>
          <div className={styles.servicesTitle}>
            <Image priority src={MenuIcon} height={16} width={16} alt="menu" />
            <span>{formatMessage({ id: 'nav.otherServices' })}</span>
          </div>
          <div className={styles.servicesWrapper}>
            {SERVICES.map(({ name, src, url }) => (
              <ServicesItem label={name} src={src} link={url} key={name} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
