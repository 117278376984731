import React from 'react';
import clsx from 'clsx';
import Image, { StaticImageData } from 'next/image';

import styles from './services-item.module.scss';

type ServicesItemProps = {
  link?: string;
  src: StaticImageData;
  label: string;
  onClick?: () => void;
};

export const ServicesItem = ({ link, src, label, onClick }: ServicesItemProps) => {
  return (
    <a href={link} onClick={onClick} target="_blank" rel="noreferrer" className={styles.servicesItem}>
      <Image src={src} alt={label} layout="fixed" width={165} height={165} />
    </a>
  );
};
